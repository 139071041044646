<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCard v-bind:title="'Records '">
          <template v-slot:title>Enrollee Timeline - Santos, Oscar M.</template>
          <template v-slot:toolbar>
            <v-btn color="success" dark>Refresh</v-btn>
          </template>
          <template v-slot:body>
            <v-timeline :dense="$vuetify.breakpoint.smAndDown">
               <v-timeline-item color="purple lighten-2" fill-dot left>
                <v-card>
                  <v-card-title class="primary lighten-2">
                    <v-icon dark size="42" class="mr-4"> mdi-account </v-icon>
                    <h2 class="display-1 white--text font-weight-light">
                      Student Registration
                    </h2>
                  </v-card-title>

                  <v-container>
                    <v-row>
                      <v-col cols="12" md="10">
                        Lorem ipsum dolor sit amet, no nam oblique veritus.
                        Commune scaevola imperdiet nec ut, sed euismod convenire
                        principes at. Est et nobis iisque percipit. <b-badge variant="primary" class="btn">view</b-badge>
                      </v-col>
                      <v-col class="hidden-sm-and-down text-right" md="2">
                        <v-icon size="64">mdi-calendar-text</v-icon>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-timeline-item>
              <v-timeline-item color="purple lighten-2" fill-dot right>
                <v-card>
                  <v-card-title class="purple lighten-2">
                    <v-icon dark size="42" class="mr-4"> mdi-magnify </v-icon>
                    <h2 class="display-1 white--text font-weight-light">
                      Assessment
                    </h2>
                  </v-card-title>

                  <v-container>
                    <v-row>
                      <v-col cols="12" md="10">
                        Lorem ipsum dolor sit amet, no nam oblique veritus.
                        Commune scaevola imperdiet nec ut, sed euismod convenire
                        principes at. Est et nobis iisque percipit. <b-badge variant="primary" class="btn">view</b-badge>
                      </v-col>
                      <v-col class="hidden-sm-and-down text-right" md="2">
                        <v-icon size="64">mdi-calendar-text</v-icon>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-timeline-item>

              <v-timeline-item color="amber lighten-1" fill-dot left small>
                <v-card>
                  <v-card-title class="amber lighten-1 justify-end">
                    <h2 class="display-1 mr-4 white--text font-weight-light">
                      Examination
                    </h2>
                    <v-icon dark size="42">mdi-note-outline</v-icon>
                  </v-card-title>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="8">
                        Lorem ipsum dolor sit amet, no nam oblique veritus.
                        Commune scaevola imperdiet nec ut, sed euismod convenire
                        principes at. Est et nobis iisque percipit. <b-badge variant="primary" class="btn">view</b-badge>
                      </v-col>
                      <v-col cols="12" md="4">
                        Lorem ipsum dolor sit amet, no nam oblique veritus.
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-timeline-item>

              <v-timeline-item color="cyan lighten-1" fill-dot right>
                <v-card>
                  <v-card-title class="cyan lighten-1">
                    <v-icon class="mr-4" dark size="42">
                      mdi-file-multiple
                    </v-icon>
                    <h2 class="display-1 white--text font-weight-light">
                      Requirements
                    </h2>
                  </v-card-title>
                  <v-container>
                    <v-row>
                      <v-col cols="6" md="6">
                        <b-form-checkbox :checked="true" disabled> Birth Certificate</b-form-checkbox>
                        <b-form-checkbox disabled> Form-137</b-form-checkbox>
                        <b-form-checkbox disabled> Form 138</b-form-checkbox>
                        
                        <!-- <v-col v-for="n in 3" :key="n" cols="12" md="4">
                        Lorem ipsum dolor sit amet, no nam oblique veritus no
                        nam oblique.
                      </v-col> -->
                      </v-col>
                      <v-col cols="6" md="6">                        
                        <b-checkbox disabled> Certificate of Good Moral Character</b-checkbox>
                        <b-checkbox disabled> Marrage Certificate</b-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-timeline-item>

              <v-timeline-item color="red lighten-1" fill-dot left small>
                <v-card>
                  <v-card-title class="red lighten-1 justify-end">
                    <h2 class="display-1 mr-4 white--text font-weight-light">
                      Payment
                    </h2>
                    <v-icon dark size="42"> mdi-currency-usd </v-icon>
                  </v-card-title>
                  <v-container>
                    <v-row>
                      <v-col class="hidden-sm-and-down" md="2">
                        <v-icon size="64">mdi-server-network</v-icon>
                      </v-col>
                      <v-col cols="12" md="10">
                        Lorem ipsum dolor sit amet, no nam oblique veritus.
                        Commune scaevola imperdiet nec ut, sed euismod convenire
                        principes at. Est et nobis iisque percipit, an vim zril
                        disputando voluptatibus.
                        
                      <b-badge variant="primary" class="btn">view</b-badge>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-timeline-item>

              <v-timeline-item color="green lighten-1" fill-dot right>
                <v-card>
                  <v-card-title class="green lighten-1">
                    <v-icon class="mr-4" dark size="42">
                      mdi-check-circle
                    </v-icon>
                    <h2 class="display-1 white--text font-weight-light">
                      Completed
                    </h2>
                  </v-card-title>
                  <v-container>
                    <v-row>
                      <v-col>
                        Lorem ipsum dolor sit amet, no nam oblique veritus.
                        Commune scaevola imperdiet nec ut, sed euismod convenire
                        principes at. Est et nobis iisque percipit, an vim zril
                        disputando voluptatibus, vix an salutandi sententiae.
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </template>
        </KTCard>
      </div>
    </div>
  </div>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

const COLORS = ["info", "warning", "error", "success"];
const ICONS = {
  info: "mdi-information",
  warning: "mdi-alert",
  error: "mdi-alert-circle",
  success: "mdi-check-circle",
};
import Vue from "vue";

// vuetify conflict style issue
// https://github.com/vuetifyjs/vuetify/issues/8530

// workaround
// https://github.com/vuetifyjs/vuetify/issues/9999
const ignoreWarnMessage = `The .native modifier for v-on is only valid on components but it was used on <div>.`;
// eslint-disable-next-line no-unused-vars
Vue.config.warnHandler = function (msg, vm, trace) {
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
};

export default {
  data() {
    return {
      interval: null,
      items: [
        {
          id: 1,
          color: "info",
          icon: ICONS["info"],
        },
      ],
      nonce: 2,
      events: [],
      input: null,
    };
  },
  components: {
    KTCard,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Enrollment", route: "/enrollment" },
      { title: "Enrollee Timeline", route: "timeline" },
    ]);
  },

  computed: {
    timeline() {
      return this.events.slice().reverse();
    },
  },

  beforeDestroy() {
    this.stop();
  },

  methods: {
    addEvent() {
      let { color, icon } = this.genAlert();

      const previousColor = this.items[0].color;

      while (previousColor === color) {
        color = this.genColor();
      }

      this.items.unshift({
        id: this.nonce++,
        color,
        icon,
      });

      if (this.nonce > 6) {
        this.items.pop();
      }
    },
    genAlert() {
      const color = this.genColor();

      return {
        color,
        icon: this.genIcon(color),
      };
    },
    genColor() {
      return COLORS[Math.floor(Math.random() * 3)];
    },
    genIcon(color) {
      return ICONS[color];
    },
    start() {
      this.interval = setInterval(this.addEvent, 3000);
    },
    stop() {
      clearInterval(this.interval);
      this.interval = null;
    },

    comment() {
      const time = new Date().toTimeString();
      this.events.push({
        id: this.nonce++,
        text: this.input,
        time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents) => {
          return ` ${contents
            .split(" ")
            .map((v) => v.charAt(0))
            .join("")}`;
        }),
      });

      this.input = null;
    },
  },
};
</script>
