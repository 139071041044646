<template>
  <div>
    <v-dialog v-model="show" max-width="290" persistent>
      <v-card tile>
        <v-toolbar flat dark color="primary" max-height="60px">        
          <v-toolbar-title><slot name="toolbar-title"></slot></v-toolbar-title>
          <v-spacer></v-spacer>
            <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-items>
            <slot name="toolbar-items"></slot>
            <!-- <v-btn dark text @click="modal = false"> Save </v-btn> -->
          </v-toolbar-items>
        </v-toolbar>
        <slot name="modal-body"></slot>
        <v-card-actions>
          <v-spacer></v-spacer>
          <slot name="modal-footer"></slot>
        </v-card-actions>
        <div style="flex: 1 1 auto"></div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "KTModal",
  props: ["visible"],
  methods: {
    close() {
      this.$emit("update:modal", false);
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
          this.$refs.observer.reset();
        }
      },
    },
  },
};
</script> 