<template>
  <div>
    <KTCard v-bind:title="' '">
      <template v-slot:toolbar>
        <div class="row">
          <div class="col-lg-12">
            <span class="float-left">
              <div class="mx-2">
                <b-form-select
                  v-model="bselected"
                  :options="options"
                  class="mb-3"
                >
                  <!-- This slot appears above the options from 'options' prop -->
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >-- Please select a filter by --</b-form-select-option
                    >
                  </template>
                </b-form-select>                
              </div>
            </span>
            <span class="float-right">
              <div class="mx-2">
                <v-btn
                  color="error"
                  dark
                  @click="code7.dialog2 = !code7.dialog2"
                >
                  Bulk Upload
                </v-btn>
                <!-- Bulk Upload Loading -->
                <v-dialog v-model="code7.dialog2" max-width="500px">
                  <v-card>
                    <v-card-title> Single/Bulk Uploading </v-card-title>
                    <v-card-text>
                      <v-file-input multiple label="File input"></v-file-input>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        text
                        @click="code7.dialog2 = false"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        :disabled="dialog"
                        :loading="dialog"
                        text
                        @click="dialog = true"
                      >
                        Upload
                      </v-btn>
                      <v-dialog
                        v-model="dialog"
                        hide-overlay
                        persistent
                        width="300"
                      >
                        <v-card color="primary" dark>
                          <v-card-text>
                            Please stand by
                            <v-progress-linear
                              indeterminate
                              color="white"
                              class="mb-0"
                            ></v-progress-linear>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- End Bulk Upload Loading -->
              </div>
            </span>
            <span class="float-right">
              <div class="mx-2">
                <router-link
                  to="/administrative/timeline"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <v-btn
                    color="warning"
                    :href="href"
                    @click="navigate"
                    :class="[isActive, isExactActive]"
                    dark
                    >View Timeline</v-btn
                  >
                </router-link>
              </div>
            </span>
            <span class="float-right">
              <div class="mx-2">
                <v-btn color="success" @click="modal = true" dark
                  >Update Status</v-btn
                >
                <KTModal :visible="modal" @close="modal = false">
                  <template v-slot:toolbar-title>Confirm</template>
                  <template v-slot:modal-body>
                    <br />
                    <v-card-text>
                      Let Google help apps determine location. This means
                      sending anonymous location data to Google, even when no
                      apps are running.
                    </v-card-text>
                  </template>

                  <template v-slot:modal-footer>
                    <v-btn color="green darken-1" text @click="modal = false">
                      Disagree
                    </v-btn>

                    <v-btn color="green darken-1" text @click="modal = false">
                      Agree
                    </v-btn>
                  </template>
                </KTModal>
              </div>
            </span>
            <span class="float-right">
              <div class="mx-2">
                <router-link
                  to="/administrative/registration"
                  v-slot="{ href, navigation }"
                >
                  <v-btn color="primary" dark :href="href" @click="navigation">
                    New
                  </v-btn>
                </router-link>
               
              </div>
            </span>
          </div>
        </div>
      </template>
      <template v-slot:body>
        <hr />
        <v-data-table
          v-model="code1.selected"
          :headers="code1.headers"
          :items="code1.desserts"
          :single-select="code1.singleSelect"
          item-key="name"
          show-select
          class="elevation-1"
        >
        </v-data-table>
      </template>
    </KTCard>
  </div>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import KTModal from "@/view/content/Modal.vue";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
      bselected: null,
      options: [
        { value: "1", text: "Pending for Registrar's Verification" },
        { value: "2", text: "Pending for Dean's Assessment" },
        { value: "3", text: "Pending for Payment Verification" },
        { value: "4", text: "Payment Verified" },
        { value: "5", text: "Officially Enrolled" },
        { value: "6", text: "Returned to the Registrar" },
        { value: "7", text: "Return to Dean" },
        { value: "8", text: "On-Hold" },        
        { value: "9", text: "Cancelled" },        
      ],
      code1: {
        singleSelect: false,
        selected: [],
        headers: [
          {
            text: "Student Name",
            align: "left",
            sortable: true,
            value: "name",
          },
          { text: "LRN/Student No.", value: "lrn" },
          { text: "Strand/Year", value: "strand" },
          { text: "Section", value: "section" },
          { text: "Semester", value: "semester" },
          { text: "Category", value: "category" },
          { text: "Status", value: "status" },
        ],
        desserts: [
          {
            name: "Santos, Oscar",
            lrn: "21-00001",
            strand: "BS101-01",
            section: "C",
            semester: "1st=Sem",
            category: "Pending Requirements",
            status: "Continuing",
          },
          {
            name: "Veloso, Enrico",
            lrn: "21-00002",
            strand: "BS404-04",
            section: "B",
            semester: "3rd-Sem",
            category: "Registered",
            status: "New",
          },
          {
            name: "Santos, Carlo Dino",
            lrn: "21-00003",
            strand: "BS201-03",
            section: "A",
            semester: "3rd-Sem",
            category: "Enrolled",
            status: "New",
          },
          {
            name: "Labindao, Erica",
            lrn: "21-00004",
            strand: "BS301-02",
            section: "A",
            semester: "2nd-Sem",
            category: "Registered",
            status: "Continuing",
          },
          {
            name: "Veloso, Karl Nathan",
            lrn: "21-00005",
            strand: "BS401-03",
            section: "A",
            semester: "1st-Sem",
            category: "Enrolled",
            status: "New",
          },
        ],
      },
      code7: {
        dialog: false,
        dialog2: false,
        dialog3: false,
        notifications: false,
        sound: true,
        widgets: false,
        items: [
          {
            title: "Click Me",
          },
          {
            title: "Click Me",
          },
          {
            title: "Click Me",
          },
          {
            title: "Click Me 2",
          },
        ],
        select: [
          { text: "State 1" },
          { text: "State 2" },
          { text: "State 3" },
          { text: "State 4" },
          { text: "State 5" },
          { text: "State 6" },
          { text: "State 7" },
        ],
      },
      activePicker: null,
      date: null,
      menu: false,
      modal: false,
      modalEnrollee: false,
    };
  },
  components: {
    KTCard,
    KTModal,
    
  },
  methods: {
    newStudent: function () {
      alert("me");
    },
    save(date) {
      this.$refs.menu.save(date);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "dashboard" },
      { title: "Enrollment", route: "enrollment" },
    ]);
  },
  watch: {
    dialog(val) {
      if (!val) return;

      setTimeout(() => (this.dialog = false), 4000);
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
};
</script>