<template>
  <v-data-table
    :headers="headers"
    :items="desserts"
    sort-by="calories"
    class="elevation-1"
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>STUDENT RECORDS</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
          New
        </v-btn>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              
              hide-details
              outlined
              dense
            ></v-text-field>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.name"
                      label="Student Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.strand"
                      label="Strad/Year"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.section"
                      label="Section"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.semester"
                      label="Semester"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.category"
                      label="Category"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.status"
                      label="Status"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px" persistent>
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.category="{ item }">
      <v-chip :color="getColor(item.category)" dark>
        {{ item.category }}
      </v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <router-link to="/administrative/profile" v-slot="{ href, navigate }">
        <v-icon small class="mr-2" @click="navigate" :href="href">
          mdi-account
        </v-icon>
      </router-link>
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template>
  </v-data-table>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Student Name",
        align: "left",
        sortable: true,
        value: "name",
      },
      { text: "LRN/Student No.", value: "lrn" },
      { text: "Strand/Year", value: "strand" },
      { text: "Section", value: "section" },
      { text: "Semester", value: "semester" },
      { text: "Category", value: "category" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      lrn: "",
      name: "",
      strand: "",
      section: "",
      semester: "",
      category: "",
      status: "",
    },
    defaultItem: {
      lrn: "",
      name: "",
      strand: "",
      section: "",
      semester: "",
      category: "",
      status: "",
    },
    search: "",
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    getColor(category) {
      if (category == "Pending Requirements") return "red";
      else if (category == "Registered") return "orange";
      else return "green";
    },
    initialize() {
      this.desserts = [
        {
          name: "Santos, Oscar",
          lrn: "21-00001",
          strand: "BS101-01",
          section: "C",
          semester: "1st=Sem",
          category: "Pending Requirements",
          status: "Continuing",
        },
        {
          name: "Veloso, Enrico",
          lrn: "21-00002",
          strand: "BS404-04",
          section: "B",
          semester: "3rd-Sem",
          category: "Registered",
          status: "New",
        },
        {
          name: "Santos, Carlo Dino",
          lrn: "21-00003",
          strand: "BS201-03",
          section: "A",
          semester: "3rd-Sem",
          category: "Enrolled",
          status: "New",
        },
        {
          name: "Labindao, Erica",
          lrn: "21-00004",
          strand: "BS301-02",
          section: "A",
          semester: "2nd-Sem",
          category: "Registered",
          status: "Continuing",
        },
        {
          name: "Veloso, Karl Nathan",
          lrn: "21-00005",
          strand: "BS401-03",
          section: "A",
          semester: "1st-Sem",
          category: "Enrolled",
          status: "New",
        },
      ];
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>