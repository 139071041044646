<template>
    <Settings v-bind:title="'Add Courses'"> 
      <template ></template>
      <template v-slot:settings-aside-content>
        <form>
          <div class="row">
            <div class="col-lg-12 col-xl-12">
              <v-select
                label="Faculty"
                outlined
                :items="[]"
                item-value="text"
                dense
              ></v-select>
              <v-select
                label="Department"
                outlined
                :items="[]"
                item-value="text"
                dense
              ></v-select>
              <v-text-field label="Course Name" outlined dense></v-text-field>
              <v-text-field label="Course Code" outlined dense></v-text-field>
            </div>
          </div>
          <hr />
          <v-btn color="primary" type="submit">Submit</v-btn>
        </form>
      </template>
      <template v-slot:settings-content>
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              dense
              outlined
            ></v-text-field>
            <v-data-table
              :headers="headers"
              :items="desserts"
              :search="search"
            ></v-data-table>
          </div>
        </div>
      </template>
    </Settings>

</template>

<script>
import Settings from "@/view/content/settings/Settings.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    Settings,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Strand", route: "strand" },
    ]);
  },
  computed: {},
};
</script>
