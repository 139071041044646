var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Dashboard")])])])]}}])}),_vm._m(0),_c('router-link',{attrs:{"to":"/administrative/enrollment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Student Registration")])])])]}}])}),_c('router-link',{attrs:{"to":"/administrative/students"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Students")])])])]}}])}),_c('router-link',{attrs:{"to":"/administrative/professor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Faculty")])])])]}}])}),_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Faculty Loading")])])])]}}])}),_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Fees")])])])]}}])}),_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Attendance")])])])]}}])}),_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Accounting")])])])]}}])}),_vm._m(1),_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('vuetify') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(2),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(3),_c('router-link',{attrs:{"to":"/administrative/academic"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Academic")])])])]}}])}),_c('router-link',{attrs:{"to":"/administrative/semester"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Semester")])])])]}}])}),_c('router-link',{attrs:{"to":"/custom-wizard/wizard-1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Department")])])])]}}])}),_c('router-link',{attrs:{"to":"/administrative/subject"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Subject")])])])]}}])}),_c('router-link',{attrs:{"to":"/administrative/strand"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Strand/Course")])])])]}}])})],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
      'menu-item-open': _vm.hasActiveChildren('/custom-wizard/wizard'),
    },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(4),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(5),_c('router-link',{attrs:{"to":"/custom-wizard/wizard-1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var href = ref.href;
    var navigate = ref.navigate;
    var isActive = ref.isActive;
    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Curriculum")])])])]}}])}),_c('router-link',{attrs:{"to":"/custom-wizard/wizard-2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("User Account")])])])]}}])})],1)])]),_vm._m(6),_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active' ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Reports")])])])]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Administrative")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Configuration")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon-web"}),_c('span',{staticClass:"menu-text"},[_vm._v("Manages")]),_c('i',{staticClass:"menu-arrow"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Manages")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-mail-1"}),_c('span',{staticClass:"menu-text"},[_vm._v("Settings")]),_c('i',{staticClass:"menu-arrow"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Settings")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Reports")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])}]

export { render, staticRenderFns }