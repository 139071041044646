<template>
  <div>
    <validation-observer ref="observer" v-slot="{ invalid }">
      
        <!--begin: Wizard-->
        <div
          class="wizard wizard-3"
          id="kt_wizard_v3"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Wizard Nav-->
          <div class="wizard-nav border-bottom mb-1 mb-lg-5">
            <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
              <div
                class="wizard-step"
                data-wizard-type="step"
                data-wizard-state="current"
              >
                <div class="wizard-label">
                  <h3 class="wizard-title"><span></span> Register Student</h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-label">
                  <h3 class="wizard-title"><span></span> Education</h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-label">
                  <h3 class="wizard-title">
                    <span></span> Contact Information
                  </h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-label">
                  <h3 class="wizard-title"><span></span> Document Upload</h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
              <div class="wizard-step" data-wizard-type="step">
                <div class="wizard-label">
                  <h3 class="wizard-title"><span></span> Summary</h3>
                  <div class="wizard-bar"></div>
                </div>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav -->

          <!--begin: Wizard Body-->
          <div
            class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10"
            style="margin-top: -5%"
          >
            <div class="col-xl-12 col-xxl-12">
              <!--begin: Wizard Form-->
              <v-container fluid>
                <form @submit.prevent="submit">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <v-row>
                      <h3>Student Information</h3>
                    </v-row>
                    <v-row>
                      <v-col cols="6" sm="3">
                        <v-checkbox
                          v-model="isNew"
                          label="New Student"
                          dense
                        ></v-checkbox>
                        <v-text-field
                          label="LRN/Student No."
                          outlined
                          dense
                          append-icon="mdi-account-search"
                          v-if="!isNew"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" sm="3">
                        <v-text-field
                          label="ID Number"
                          outlined
                          disabled
                          hint="example of helper text only on focus"
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Last Name"
                          rules="required"
                        >
                          <v-text-field
                            label="Last Name"
                            required
                            outlined
                            dense
                            v-model="lastName"
                            :error-messages="errors"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="First Name"
                          rules="required"
                        >
                          <v-text-field
                            label="First Name"
                            outlined
                            hint="example of helper text only on focus"
                            dense
                            v-model="firstName"
                            :error-messages="errors"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <v-text-field
                          label="Middle name"
                          hint="example of persistent helper text"
                          persistent-hint
                          outlined
                          dense
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Gender"
                          rules="required"
                        >
                          <v-select
                            label="Gender"
                            dense
                            outlined
                            required
                            v-model="sex"
                            :items="gender"
                            :error-messages="errors"
                          ></v-select>
                        </validation-provider>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Gender"
                          rules="required"
                        >
                          <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="date"
                                label="Birthday date"
                                append-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :error-messages="errors"
                                dense
                                outlined
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="date"
                              :active-picker.sync="activePicker"
                              :max="new Date().toISOString().substr(0, 10)"
                              min="1950-01-01"
                              @change="save"
                            ></v-date-picker>
                          </v-menu>
                        </validation-provider>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Place of Birth"
                          rules="required"
                        >
                          <v-text-field
                            label="Birth Place"
                            outlined
                            dense
                            v-model="birthPlace"
                            :error-messages="errors"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Age"
                          rules="required"
                        >
                          <v-select
                            :items="['0-17', '18-29', '30-54', '54+']"
                            label="Age"
                            v-model="age"
                            :error-messages="errors"
                            data-vv-name="age"
                            outlined
                            required
                            dense
                          ></v-select>
                        </validation-provider>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Marital Status"
                          rules="required"
                        >
                          <v-select
                            label="Marital Status"
                            item-text="text"
                            item-value="id"
                            :error-messages="errors"
                            v-model="mstat"
                            outlined
                            required
                            dense
                          ></v-select>
                        </validation-provider>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Email"
                          rules="required|email"
                        >
                          <v-text-field
                            label="Email*"
                            dense
                            outlined
                            required
                            :error-messages="errors"
                            v-model="email"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Religion"
                          rules="required"
                        >
                          <v-select
                            label="Religion"
                            dense
                            outlined
                            required
                            v-model="religion"
                            :items="gender"
                            :error-messages="errors"
                          ></v-select>
                        </validation-provider>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Citizenship"
                          rules="required"
                        >
                          <v-checkbox
                            label="Is foreign student"
                            dense
                            required
                            v-model="isForeign"
                            :error-messages="errors"
                          ></v-checkbox>
                        </validation-provider>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Nationality"
                          rules="required"
                        >
                          <v-select
                            label="Nationality"
                            dense
                            rows="1"
                            outlined
                            :required="isForeign"
                            :error-messages="errors"
                            v-model="nationality"
                            :disabled="!isForeign"
                          ></v-select>
                        </validation-provider>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Street/House No."
                          rules="required"
                        >
                          <v-textarea
                            label="Street/House No."
                            dense
                            rows="1"
                            outlined
                            required
                            :error-messages="errors"
                            v-model="street"
                          ></v-textarea>
                        </validation-provider>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="State/Municipality"
                          rules="required"
                        >
                          <v-select
                            label="State/Municipallity"
                            dense
                            outlined
                            required
                            :error-messages="errors"
                            v-model="municipality"
                          ></v-select>
                        </validation-provider>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="City"
                          rules="required"
                        >
                          <v-select
                            label="City"
                            dense
                            outlined
                            required
                            v-model="city"
                            :items="['0-17', '18-29', '30-54', '54+']"
                            :error-messages="errors"
                          ></v-select>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Step 2-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <v-row>
                      <h3>Enrollment Details</h3>
                    </v-row>
                    <v-row>
                      <v-col cols="6" sm="3">
                        <v-select
                          label="Academic Year"
                          :items="[]"
                          item-value="text"
                          dense
                          required=""
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <v-select
                          label="Programs Offered"
                          :items="[]"
                          dense
                          required
                          item-value="text"
                          outlined
                        />
                      </v-col>
                      <v-col cols="6" sm="3">
                        <v-select
                          label="Year/Level"
                          :items="[]"
                          dense
                          required
                          item-value="text"
                          outlined
                        />
                      </v-col>
                      <v-col cols="6" sm="3">
                        <v-select
                          label="Strand"
                          :items="[]"
                          dense
                          required
                          item-value="text"
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <br />
                    <v-row>
                      <h3>Previous School Details</h3>
                    </v-row>
                    <v-row>
                      <v-col cols="6" sm="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Last School Attended"
                          rules="required"
                        >
                          <v-text-field
                            label="Last School Attended"
                            hint="example of persistent helper text"
                            persistent-hint
                            outlined
                            dense
                            required
                            :error-messages="errors"
                            v-model="lastSchAttended"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <v-text-field
                          label="Year"
                          hint="example of persistent helper text"
                          persistent-hint
                          outlined
                          dense
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <v-text-field
                          label="School Address"
                          persistent-hint
                          outlined
                          dense
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <v-text-field
                          label="State/Municipality"
                          outlined
                          dense
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <v-text-field
                          label="City"
                          outlined
                          dense
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <v-text-field
                          label="Phone Number"
                          dense
                          outlined
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="6" sm="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="General Average"
                          rules="required"
                        >
                          <v-select
                            label="Status"
                            dense
                            :items="enrollmentStatus.items"
                            item-value="text"
                            outlined
                            :error-messages="errors"
                            v-model="enStatus"
                            required
                          ></v-select>
                        </validation-provider>
                      </v-col>

                      <v-col cols="6" sm="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="General Average"
                          rules="required"
                        >
                          <v-text-field
                            label="General Average"
                            dense
                            outlined
                            required
                            :error-messages="errors"
                            v-model="generalAvg"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </div>
                  <!--end: Wizard Step 2-->

                  <!--begin: Wizard Step 3-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <v-row>
                      <h3>Contact Details</h3>
                    </v-row>
                    <v-row>
                      <v-col cols="6" sm="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Guardian"
                          rules="required"
                        >
                          <v-text-field
                            label="Name of Guardian"
                            outlined
                            hint=""
                            dense
                            :error-messages="errors"
                            v-model="guardian"
                            required
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Guardian's Contact Number"
                          rules="required"
                        >
                          <v-text-field
                            label="Guardian's Contact Number"
                            hint="example of persistent helper text"
                            persistent-hint
                            outlined
                            dense
                            :error-messages="errors"
                            v-model="gContactNo"
                            required
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <v-text-field
                          label="Alternate Contact Number"
                          hint="example of persistent helper text"
                          persistent-hint
                          outlined
                          dense
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Guardian's Address"
                          rules="required"
                        >
                          <v-textarea
                            label="Guardian's Address"
                            hint="example of persistent helper text"
                            persistent-hint
                            rows="1"
                            outlined
                            dense
                            required
                            :error-messages="errors"
                            v-model="guardianAdd"
                          ></v-textarea>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </div>
                  <!--end: Wizard Step 3-->

                  <!--begin: Wizard Step 4-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <v-row>
                      <h3>Uplad Your Requirements</h3>
                    </v-row>
                    <div class="form-group row">
                      <v-col cols="12" sm="3">
                        <b-select
                          readonly
                          label="Category"
                          outlined
                          dense
                        ></b-select>
                      </v-col>
                    </div>
                    <div class="form-group row">
                      <div class="col-lg-12 col-sm-12">
                        <b-form-file
                          v-model="file"
                          ref="file-input"
                          class="mb-2"
                        ></b-form-file>

                        <b-button @click="file = null" variant="primary"
                          >Process Upload</b-button
                        >
                        <p class="mt-2">
                          Selected file: <b>{{ file ? file.name : "" }}</b>
                        </p>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-lg-12 col-sm-12">
                        <v-data-table
                          v-model="code1.selected"
                          :headers="code1.headers"
                          :items="code1.desserts"
                          item-key="name"
                          class="elevation-1"
                        >
                          <template v-slot:item.status="{ item }">
                            <v-chip small :color="getColor(item.status)" dark>
                              {{ item.status }}
                            </v-chip>
                          </template>
                        </v-data-table>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 4-->

                  <!--begin: Wizard Step 5-->

                  <div class="pb-5" data-wizard-type="step-content">
                    <div class="mb-10 font-weight-bold text-dark">
                      Review your Details and Submit
                    </div>
                    <div class="border-bottom mb-5 pb-5">
                      <div class="font-weight-bold mb-3">
                        Your Account Details:
                      </div>
                      <div class="line-height-md">
                        John Wick
                        <br />
                        Phone: +61412345678 <br />
                        Email: johnwick@reeves.com
                      </div>
                    </div>
                    <div class="border-bottom mb-5 pb-5">
                      <div class="font-weight-bold mb-3">
                        Your Address Details:
                      </div>
                      <div class="line-height-md">
                        Address Line 1
                        <br />
                        Address Line 2 <br />
                        Melbourne 3000, VIC, Australia
                      </div>
                    </div>
                    <div class="mb-5">
                      <div class="font-weight-bold mb-3">Payment Details:</div>
                      <div class="line-height-md">
                        Card Number: xxxx xxxx xxxx 1111
                        <br />
                        Card Name: John Wick <br />
                        Card Expiry: 01/21
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 5-->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div class="mr-2">
                      <button
                        class="
                          btn btn-light-primary
                          font-weight-bold
                          text-uppercase
                          px-9
                          py-4
                        "
                        data-wizard-type="action-prev"
                      >
                        Previous
                      </button>
                    </div>
                    <div>
                      <button
                        v-on:click="submit"
                        class="
                          btn btn-success
                          font-weight-bold
                          text-uppercase
                          px-9
                          py-4
                        "
                        data-wizard-type="action-submit"
                      >
                        Submit
                      </button>
                      <!-- <v-btn
                        :disabled="!invalid"
                        v-on:click="submit"
                        color="success"
                        dark
                        data-wizard-type="action-submit"
                      >
                        Submit
                      </v-btn> -->
                      <button
                        class="
                          btn btn-primary
                          font-weight-bold
                          text-uppercase
                          px-9
                          py-4
                        "
                        data-wizard-type="action-next"
                      >
                        Next Step
                      </button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
              </v-container>
              <!--end: Wizard Form-->
            </div>
          </div>

          <!--end: Wizard Bpdy-->
        </div>
        <!--end: Wizard-->
      
    </validation-observer>
    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});

export default {
  name: "WizEnrollee",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      file: null,
      isNew: true,
      isForeign: false,
      dialog: false,
      date: null,
      activePicker: null,
      isBirthUploaded: false,
      menu: false,
      code1: {
        headers: [
          {
            text: "Id",
            align: "left",
            sortable: false,
            value: "id",
            visible: false,
          },
          { text: "File Name", value: "name" },
          { text: "Category", value: "category" },
          { text: "Status", value: "status" },
        ],
        desserts: [
          {
            id: 1,
            name: "Birth Certificate.pdf",
            category: "Pending Requirements",
            status: "Approved",
          },
          {
            id: 1,
            name: "Form-137.pdf",
            category: "Form-137",
            status: "For Approval",
          },
          {
            id: 1,
            name: "Form-137.pdf",
            category: "Form-137",
            status: "Declined",
          },
        ],
      },
      gender: [{ text: "Female" }, { text: "Male" }],
      marital: {
        items: [
          { text: "Single", id: 1 },
          { text: "Married", id: 2 },
          { text: "Separated", id: 3 },
          { text: "Widowed", id: 4 },
        ],
      },
      enrollmentStatus: {
        items: [
          { text: "Transferee" },
          { text: "Continuing" },
          { text: "New Student" },
        ],
      },
    };
  },
  mounted() {
    //tabs
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Student Registration", route: "/enrollment" },
      { title: "New Registration" },
    ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v3", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function (/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    submit: function (e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "The application has been successfully submitted!",
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
      });
      this.$refs.observer.validate();
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    getColor(status) {
      if (status == "Declined") return "red";
      else if (status == "For Approval") return "orange";
      else return "green";
    },
  },
  watch: {
    //calendar
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    dialog(val) {
      if (!val) return;

      setTimeout(() => (this.dialog = false), 4000);
    },
  },
};
</script>
