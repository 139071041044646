import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/administrative",
          name: "administrative",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "enrollment",
              name: "enrollment",
              component: () => import("@/module/components/enrollment/EnrollmentList.vue")
            },
            {
              path: "registration",
              name: "registration",
              component: () => import("@/module/components/enrollment/Enrollee.vue")
            },

            {
              path: "students",
              name: "students",
              component: () => import("@/module/components/student/StudentList.vue")
            },
            {
              path: "timeline",
              name: "timeline",
              component: () => import("@/module/components/enrollment/timeline/Timeline.vue")
            },
            {
              path: "profile",
              name: "profile",
              component: () => import("@/view/pages/profile/Profile-1.vue")
            },
            {
              path: "strand",
              name: "strand",
              component: () => import("@/module/components/strand/Strand.vue")
            },
            {
              path: "professor",
              name: "professor",
              component: () => import("@/module/components/faculty/Faculty.vue"),
            },
            {
              path: "facultyProfile",
              name: "facultyProfile",
              component: () => import("@/module/components/faculty/profile/Profile-1.vue")

            },
            {
              path: "subject",
              name: "subject",
              component: () => import("@/module/components/subject/Subject.vue")
            },
            {
              path: "academic",
              name: "academic",
              component: () => import("@/module/components/academic/Academic.vue")
            },
            {
              path: "semester",
              name: "semester",
              component: () => import("@/module/components/semester/Semester.vue")
            },

            {
              path: "enrollee",
              name: "enrollee",
              component: () => import("@/module/components/enrollment/WizEnrollee.vue")
            },
          ]
        },
        {
          path: "/custom-wizard",
          name: "wizard",
          component: () => import("@/view/pages/wizard/Wizard.vue"),
          children: [

            {
              path: "wizard-1",
              name: "wizard-1",
              component: () => import("@/view/pages/wizard/Wizard-1.vue")
            },
            {
              path: "wizard-2",
              name: "wizard-2",
              component: () => import("@/view/pages/wizard/Wizard-2.vue")
            },
            {
              path: "wizard-3",
              name: "wizard-3",
              component: () => import("@/view/pages/wizard/Wizard-3.vue")
            },
            {
              path: "wizard-4",
              name: "wizard-4",
              component: () => import("@/view/pages/wizard/Wizard-4.vue")
            }
          ]
        },
        {
          path: "/custom-plugins",
          name: "plugins",
          component: () => import("@/view/pages/plugins/Plugins.vue"),
          children: [
            {
              path: "cropper",
              name: "cropper",
              component: () => import("@/view/pages/plugins/Cropper.vue")
            },
            {
              path: "treeselect",
              name: "treeselect",
              component: () => import("@/view/pages/plugins/Treeselect.vue")
            }
          ]
        }
      ]
    },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
