<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Eductional Background
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Update your eductional background information</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <h5 class="font-weight-bold mt-5 mb-3">Tertiary Info</h5>
          </div>
          <div class="col-lg-6 col-xl-6">
            <v-text-field
              :rules="rules"
              label="School"
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-lg-6 col-xl-6">
            <v-select
              :items="code7.select"
              label="School Year"
              item-value="text"
              outlined
              dense
            ></v-select>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <h5 class="font-weight-bold mt-5 mb-3">Secondary Info</h5>
          </div>
          <div class="col-lg-6 col-xl-6">
            <v-text-field
              :rules="rules"
              label="School"
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-lg-6 col-xl-6">
            <v-select
              :items="code7.select"
              label="School Year"
              item-value="text"
              outlined
              dense
            ></v-select>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-xl-6">
            <h5 class="font-weight-bold mt-5 mb-3">Trainings/Seminars</h5>
          </div>
          <div class="col-lg-12 col-xl-12">
            <v-card>
              <v-card-title>
                <v-btn class="mx-2" color="primary" dark>Add</v-btn>
                <v-btn color="error" dark>Delete</v-btn>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="desserts"
                :search="search"
              ></v-data-table>
            </v-card>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-xl-6">
            <h5 class="font-weight-bold mt-5 mb-3">Certifications/Diploma</h5>
          </div>
          <div class="col-lg-12 col-xl-12">
            <v-card>
              <v-card-title>
                <v-btn class="mx-2" color="primary" dark>Add</v-btn>
                <v-btn color="error" dark>Delete</v-btn>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="desserts"
                :search="search"
              ></v-data-table>
            </v-card>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";

export default {
  name: "PersonalInformation",
  data() {
    return {
      default_photo: "media/users/blank.png",
      current_photo: null,
      title: "Preliminary report",
      description: "California is a state in the western United States",
      rules: [(v) => v.length <= 25 || "Max 25 characters"],
      code7: {
        dialog: false,
        dialog2: false,
        dialog3: false,
        notifications: false,
        sound: true,
        widgets: false,
        items: [
          {
            title: "Click Me",
          },
          {
            title: "Click Me",
          },
          {
            title: "Click Me",
          },
          {
            title: "Click Me 2",
          },
        ],
        select: [
          { text: "State 1" },
          { text: "State 2" },
          { text: "State 3" },
          { text: "State 4" },
          { text: "State 5" },
          { text: "State 6" },
          { text: "State 7" },
        ],
      },
      search: "",
      headers: [
        {
          text: "Dessert (100g serving)",
          align: "left",
          sortable: false,
          value: "name",
        },
        { text: "Calories", value: "calories" },
        { text: "Fat (g)", value: "fat" },
        { text: "Carbs (g)", value: "carbs" },
        { text: "Protein (g)", value: "protein" },
        { text: "Iron (%)", value: "iron" },
      ],
      desserts: [
        {
          name: "Eclair",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          iron: "7%",
        },
        {
          name: "Cupcake",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          iron: "8%",
        },
        {
          name: "Gingerbread",
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          iron: "16%",
        },
        {
          name: "Jelly bean",
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          iron: "0%",
        },
        {
          name: "Lollipop",
          calories: 392,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          iron: "2%",
        },
      ],
    };
  },
  mounted() {
    this.current_photo = this.currentUserPersonalInfo.photo;
  },
  methods: {
    save() {
      var name = this.$refs.name.value;
      var surname = this.$refs.surname.value;
      var company_name = this.$refs.company_name.value;
      var phone = this.$refs.phone.value;
      var email = this.$refs.email.value;
      var company_site = this.$refs.company_site.value;
      var photo = this.photo;

      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch(UPDATE_PERSONAL_INFO, {
          name,
          surname,
          company_name,
          phone,
          email,
          company_site,
          photo,
        });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    cancel() {
      this.$refs.name.value = this.currentUserPersonalInfo.name;
      this.$refs.surname.value = this.currentUserPersonalInfo.surname;
      this.$refs.company_name.value = this.currentUserPersonalInfo.company_name;
      this.$refs.phone.value = this.currentUserPersonalInfo.phone;
      this.$refs.email.value = this.currentUserPersonalInfo.email;
      this.$refs.company_site.value = this.currentUserPersonalInfo.company_site;
      this.current_photo = this.currentUserPersonalInfo.photo;
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
};
</script>
