<template>
  <div class="d-flex flex-row">
    <div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px">
      <v-card tile v-bind:class="classes" min-height="100%">
        <v-toolbar
          color="primary"
          dark
          v-bind:class="headClass"
          v-if="hasTitleSlot || title"
        >
          <v-toolbar-title>
            <slot name="settings-aside-title" v-if="hasTitleSlot"></slot>
            <h3 v-if="!hasTitleSlot">
              {{ title }}
            </h3>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <slot name="settings-aside-content"></slot>
        </v-card-text>
      </v-card>
    </div>
    <div class="flex-row-fluid ml-lg-8">
      <v-card tile min-height="100%">
        <v-card-text>
          <slot name="settings-content"></slot>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    headClass: String,
  },
  name: "Settings",
  computed: {
    hasTitleSlot() {
      return !!this.$slots["title"];
    },
    classes() {
      const cls = {
        "example example-compact": this.example,
        "height-fluid": this.fluidHeight,
        "height-fluid-half": this.fluidHalfHeight,
        "head-overlay": this.headOverlay,
      };

      cls[this.headSizeClass] = this.headSizeClass;

      // append extra classes
      if (this.cardClass) {
        cls[this.cardClass] = true;
      }

      return cls;
    },
  },
};
</script>
