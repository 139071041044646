<template>
  <v-app>
    <div>
      <KTCard v-bind:title="'Records '">
        <template v-slot:toolbar>
          <div class="mx-2">
            <router-link
              to="/administrative/facultyProfile"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <v-btn
                color="success"
                :href="href"
                @click="navigate"
                :class="[isActive, isExactActive]"
                dark
                >Profile</v-btn
              >
            </router-link>
          </div>
          <div class="mx-2">
            <v-row>
              <v-btn
                color="primary"
                class="mx-2"
                dark
                @click="code7.dialog = true"
              >
                New
              </v-btn>
              <!-- New/Edit Dialog -->
              <v-dialog
                v-model="code7.dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                scrollable
              >
                <v-card tile>
                  <v-toolbar flat dark color="primary">
                    <v-btn icon dark @click="code7.dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>New Student</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn dark text @click="code7.dialog = false">
                        Save
                      </v-btn>
                    </v-toolbar-items>
                    <v-menu bottom right offset-y>
                      <template v-slot:activator="{ on }">
                        <v-btn dark icon v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          v-for="(item, i) in code7.items"
                          :key="i"
                          @click="() => {}"
                        >
                          <v-list-item-title>{{
                            item.title
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-toolbar>
                  <br />
                  <v-card-text>
                    <v-list three-line subheader>
                      <v-list-item-title
                        >Perosnal Information</v-list-item-title
                      >
                      <v-list-item>
                        <v-list-item-content>
                          <v-form>
                            <v-row>
                              <v-col cols="12" sm="3">
                                <v-text-field
                                  v-model="lastName"
                                  :rules="rules"
                                  counter="25"
                                  hint="This field uses counter prop"
                                  label="Last Name"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" sm="3">
                                <v-text-field
                                  v-model="firstName"
                                  :rules="rules"
                                  counter="25"
                                  hint="This field uses maxlength attribute"
                                  label="First Name"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" sm="3">
                                <v-text-field
                                  v-model="middleName"
                                  :rules="rules"
                                  counter="25"
                                  hint="This field uses counter prop"
                                  label="Middle Name"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" sm="3">
                                <v-select
                                  :items="code7.select"
                                  label="Age"
                                  item-value="text"
                                ></v-select>
                              </v-col>

                              <v-col cols="12" sm="3">
                                <!-- <div class="mb-6">
                                  
                                  <code>{{ activePicker || "null" }}</code>
                                </div> -->
                                <v-menu
                                  ref="menu"
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="date"
                                      label="Birthday date"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="date"
                                    :active-picker.sync="activePicker"
                                    :max="
                                      new Date().toISOString().substr(0, 10)
                                    "
                                    min="1950-01-01"
                                    @change="save"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>

                              <v-col cols="12" sm="3">
                                <v-select
                                  :items="code7.select"
                                  label="Gender"
                                  item-value="text"
                                ></v-select>
                              </v-col>

                              <v-col cols="12" sm="3">
                                <v-textarea
                                  label="Address"
                                  rows="1"
                                ></v-textarea>
                              </v-col>

                              <v-col cols="12" sm="3">
                                <v-select
                                  :items="code7.select"
                                  label="Religion"
                                  item-value="text"
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-divider></v-divider>
                    <v-list three-line subheader>
                      <v-list-item-title>Academic Details</v-list-item-title>
                      <v-list-item>
                        <v-list-item-content>
                          <v-form>
                            <v-row>
                              <v-col cols="12" sm="3">
                                <v-text-field
                                  v-model="lastSchAttended"
                                  :rules="rules"
                                  counter="100"
                                  hint="This field uses counter prop"
                                  label="Last School Attended"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" sm="3">
                                <v-textarea
                                  label="School Address"
                                  rows="1"
                                ></v-textarea>
                              </v-col>

                              <v-col cols="12" sm="3">
                                <v-text-field
                                  v-model="gradeYr"
                                  :rules="rules"
                                  counter="25"
                                  hint="This field uses maxlength attribute"
                                  label="Grade/Year"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" sm="3">
                                <v-text-field
                                  v-model="middleName"
                                  :rules="rules"
                                  counter="25"
                                  hint="This field uses counter prop"
                                  label="Middle Name"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" sm="3">
                                <v-select
                                  :items="code7.select"
                                  label="Age"
                                  item-value="text"
                                ></v-select>
                              </v-col>

                              <v-col cols="12" sm="3">
                                <v-menu
                                  ref="menu"
                                  v-model="menu1"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="date"
                                      label="Birthday date"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="date"
                                    :active-picker.sync="activePicker"
                                    :max="
                                      new Date().toISOString().substr(0, 10)
                                    "
                                    min="1950-01-01"
                                    @change="save"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>

                              <v-col cols="12" sm="3">
                                <v-select
                                  :items="code7.select"
                                  label="Gender"
                                  item-value="text"
                                ></v-select>
                              </v-col>

                              <v-col cols="12" sm="3">
                                <v-textarea
                                  label="Address"
                                  rows="1"
                                ></v-textarea>
                              </v-col>

                              <v-col cols="12" sm="3">
                                <v-select
                                  :items="code7.select"
                                  label="Religion"
                                  item-value="text"
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-action>
                          <v-checkbox v-model="code7.widgets"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title
                            >Auto-add widgets</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >Automatically add home screen
                            widgets</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                  <div style="flex: 1 1 auto"></div>
                </v-card>
              </v-dialog>
              <!--End New/Edit Dialog -->

              <!-- Bulk Upload -->
              <v-dialog v-model="code7.dialog3" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span>Dialog 3</span>
                    <v-spacer></v-spacer>
                    <v-menu bottom left>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, i) in code7.items"
                          :key="i"
                          @click="() => {}"
                        >
                          <v-list-item-title>{{
                            item.title
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-card-title>
                  <v-card-actions>
                    <v-btn color="primary" text @click="code7.dialog3 = false">
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- End Bulk Upload -->
            </v-row>
          </div>

          <div class="mx-2">
            <v-btn color="warning" @click="code8.dialog = true" dark
              >Update</v-btn
            >
          </div>
          <div class="mx-2">
            <v-btn color="error" dark @click="code7.dialog2 = !code7.dialog2">
              Bulk Upload
            </v-btn>
            <!-- Bulk Upload Loading -->
            <v-dialog v-model="code7.dialog2" max-width="500px">
              <v-card>
                <v-card-title> Single/Bulk Uploading </v-card-title>
                <v-card-text>
                  <v-file-input multiple label="File input"></v-file-input>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" text @click="code7.dialog2 = false">
                    Close
                  </v-btn>
                  <v-btn
                    :disabled="dialog"
                    :loading="dialog"
                    text
                    @click="dialog = true"
                  >
                    Upload
                  </v-btn>
                  <v-dialog
                    v-model="dialog"
                    hide-overlay
                    persistent
                    width="300"
                  >
                    <v-card color="primary" dark>
                      <v-card-text>
                        Please stand by
                        <v-progress-linear
                          indeterminate
                          color="white"
                          class="mb-0"
                        ></v-progress-linear>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- End Bulk Upload Loading -->
          </div>
        </template>
        <template v-slot:body>
          <v-data-table
            v-model="code1.selected"
            :headers="code1.headers"
            :items="code1.desserts"
            :single-select="code1.singleSelect"
            item-key="name"
            show-select
            class="elevation-1"
          >
            <template v-slot:top>
              <v-switch
                v-model="code1.singleSelect"
                label="Single select"
                class="pa-3"
              ></v-switch>
            </template>
          </v-data-table>
        </template>
      </KTCard>
    </div>
  </v-app>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
      code1: {
        html: `<v-data-table
  v-model="selected"
  :headers="headers"
  :items="desserts"
  :single-select="singleSelect"
  item-key="name"
  show-select
  class="elevation-1"
>
  <template v-slot:top>
    <v-switch v-model="singleSelect" label="Single select" class="pa-3"></v-switch>
  </template>
</v-data-table>`,
        js: `export default {
  data () {
    return {
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: 'Dessert (100g serving)',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        { text: 'Calories', value: 'calories' },
        { text: 'Fat (g)', value: 'fat' },
        { text: 'Carbs (g)', value: 'carbs' },
        { text: 'Protein (g)', value: 'protein' },
        { text: 'Iron (%)', value: 'iron' },
      ],
      desserts: [
        {
          name: 'Frozen Yogurt',
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: '1%',
        },
        {
          name: 'Ice cream sandwich',
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: '1%',
        },
        {
          name: 'Eclair',
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          iron: '7%',
        },
        {
          name: 'Cupcake',
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          iron: '8%',
        },
        {
          name: 'Gingerbread',
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          iron: '16%',
        },
        {
          name: 'Jelly bean',
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          iron: '0%',
        },
        {
          name: 'Lollipop',
          calories: 392,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          iron: '2%',
        },
        {
          name: 'Honeycomb',
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
          iron: '45%',
        },
        {
          name: 'Donut',
          calories: 452,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          iron: '22%',
        },
        {
          name: 'KitKat',
          calories: 518,
          fat: 26.0,
          carbs: 65,
          protein: 7,
          iron: '6%',
        },
      ],
    }
  },
}`,
        singleSelect: false,
        selected: [],
        headers: [
          {
            text: "Dessert (100g serving)",
            align: "left",
            sortable: false,
            value: "name",
          },
          { text: "Calories", value: "calories" },
          { text: "Fat (g)", value: "fat" },
          { text: "Carbs (g)", value: "carbs" },
          { text: "Protein (g)", value: "protein" },
          { text: "Iron (%)", value: "iron" },
        ],
        desserts: [
          {
            name: "Frozen Yogurt",
            calories: 159,
            fat: 6.0,
            carbs: 24,
            protein: 4.0,
            iron: "1%",
          },
          {
            name: "Ice cream sandwich",
            calories: 237,
            fat: 9.0,
            carbs: 37,
            protein: 4.3,
            iron: "1%",
          },
          {
            name: "Eclair",
            calories: 262,
            fat: 16.0,
            carbs: 23,
            protein: 6.0,
            iron: "7%",
          },
          {
            name: "Cupcake",
            calories: 305,
            fat: 3.7,
            carbs: 67,
            protein: 4.3,
            iron: "8%",
          },
          {
            name: "Gingerbread",
            calories: 356,
            fat: 16.0,
            carbs: 49,
            protein: 3.9,
            iron: "16%",
          },
          {
            name: "Jelly bean",
            calories: 375,
            fat: 0.0,
            carbs: 94,
            protein: 0.0,
            iron: "0%",
          },
          {
            name: "Lollipop",
            calories: 392,
            fat: 0.2,
            carbs: 98,
            protein: 0,
            iron: "2%",
          },
          {
            name: "Honeycomb",
            calories: 408,
            fat: 3.2,
            carbs: 87,
            protein: 6.5,
            iron: "45%",
          },
          {
            name: "Donut",
            calories: 452,
            fat: 25.0,
            carbs: 51,
            protein: 4.9,
            iron: "22%",
          },
          {
            name: "KitKat",
            calories: 518,
            fat: 26.0,
            carbs: 65,
            protein: 7,
            iron: "6%",
          },
        ],
      },

      code2: {
        html: `<v-data-table
  :headers="headers"
  :items="desserts"
  item-key="name"
  group-by="category"
  class="elevation-1"
  show-group-by
></v-data-table>`,
        js: `export default {
    data () {
      return {
        headers: [
          {
            text: 'Dessert (100g serving)',
            align: 'left',
            value: 'name',
          },
          { text: 'Category', value: 'category' },
        ],
        desserts: [
          {
            name: 'Frozen Yogurt',
            category: 'Ice cream',
          },
          {
            name: 'Ice cream sandwich',
            category: 'Ice cream',
          },
          {
            name: 'Eclair',
            category: 'Cookie',
          },
          {
            name: 'Cupcake',
            category: 'Pastry',
          },
          {
            name: 'Gingerbread',
            category: 'Cookie',
          },
          {
            name: 'Jelly bean',
            category: 'Candy',
          },
          {
            name: 'Lollipop',
            category: 'Candy',
          },
          {
            name: 'Honeycomb',
            category: 'Toffee',
          },
          {
            name: 'Donut',
            category: 'Pastry',
          },
          {
            name: 'KitKat',
            category: 'Candy',
          },
        ],
      }
    },
  }`,
        headers: [
          {
            text: "Dessert (100g serving)",
            align: "left",
            value: "name",
          },
          { text: "Category", value: "category" },
        ],
        desserts: [
          {
            name: "Frozen Yogurt",
            category: "Ice cream",
          },
          {
            name: "Ice cream sandwich",
            category: "Ice cream",
          },
          {
            name: "Eclair",
            category: "Cookie",
          },
          {
            name: "Cupcake",
            category: "Pastry",
          },
          {
            name: "Gingerbread",
            category: "Cookie",
          },
          {
            name: "Jelly bean",
            category: "Candy",
          },
          {
            name: "Lollipop",
            category: "Candy",
          },
          {
            name: "Honeycomb",
            category: "Toffee",
          },
          {
            name: "Donut",
            category: "Pastry",
          },
          {
            name: "KitKat",
            category: "Candy",
          },
        ],
      },

      code3: {
        html: `<v-data-table
  :headers="headers"
  :items="desserts"
  :sort-by="['calories', 'fat']"
  :sort-desc="[false, true]"
  multi-sort
  class="elevation-1"
></v-data-table>`,
        js: ` export default {
  data () {
    return {
      headers: [
        {
          text: 'Dessert (100g serving)',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        { text: 'Calories', value: 'calories' },
        { text: 'Fat (g)', value: 'fat' },
        { text: 'Carbs (g)', value: 'carbs' },
        { text: 'Protein (g)', value: 'protein' },
        { text: 'Iron (%)', value: 'iron' },
      ],
      desserts: [
        {
          name: 'Frozen Yogurt',
          calories: 200,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: '1%',
        },
        {
          name: 'Ice cream sandwich',
          calories: 200,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: '1%',
        },
        {
          name: 'Eclair',
          calories: 300,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          iron: '7%',
        },
        {
          name: 'Cupcake',
          calories: 300,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          iron: '8%',
        },
        {
          name: 'Gingerbread',
          calories: 400,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          iron: '16%',
        },
        {
          name: 'Jelly bean',
          calories: 400,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          iron: '0%',
        },
        {
          name: 'Lollipop',
          calories: 400,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          iron: '2%',
        },
        {
          name: 'Honeycomb',
          calories: 400,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
          iron: '45%',
        },
        {
          name: 'Donut',
          calories: 500,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          iron: '22%',
        },
        {
          name: 'KitKat',
          calories: 500,
          fat: 26.0,
          carbs: 65,
          protein: 7,
          iron: '6%',
        },
      ],
    }
  },
}`,
        headers: [
          {
            text: "Dessert (100g serving)",
            align: "left",
            sortable: false,
            value: "name",
          },
          { text: "Calories", value: "calories" },
          { text: "Fat (g)", value: "fat" },
          { text: "Carbs (g)", value: "carbs" },
          { text: "Protein (g)", value: "protein" },
          { text: "Iron (%)", value: "iron" },
        ],
        desserts: [
          {
            name: "Frozen Yogurt",
            calories: 200,
            fat: 6.0,
            carbs: 24,
            protein: 4.0,
            iron: "1%",
          },
          {
            name: "Ice cream sandwich",
            calories: 200,
            fat: 9.0,
            carbs: 37,
            protein: 4.3,
            iron: "1%",
          },
          {
            name: "Eclair",
            calories: 300,
            fat: 16.0,
            carbs: 23,
            protein: 6.0,
            iron: "7%",
          },
          {
            name: "Cupcake",
            calories: 300,
            fat: 3.7,
            carbs: 67,
            protein: 4.3,
            iron: "8%",
          },
          {
            name: "Gingerbread",
            calories: 400,
            fat: 16.0,
            carbs: 49,
            protein: 3.9,
            iron: "16%",
          },
          {
            name: "Jelly bean",
            calories: 400,
            fat: 0.0,
            carbs: 94,
            protein: 0.0,
            iron: "0%",
          },
          {
            name: "Lollipop",
            calories: 400,
            fat: 0.2,
            carbs: 98,
            protein: 0,
            iron: "2%",
          },
          {
            name: "Honeycomb",
            calories: 400,
            fat: 3.2,
            carbs: 87,
            protein: 6.5,
            iron: "45%",
          },
          {
            name: "Donut",
            calories: 500,
            fat: 25.0,
            carbs: 51,
            protein: 4.9,
            iron: "22%",
          },
          {
            name: "KitKat",
            calories: 500,
            fat: 26.0,
            carbs: 65,
            protein: 7,
            iron: "6%",
          },
        ],
      },

      code4: {
        html: `<v-card>
  <v-card-title>
    Nutrition
    <v-spacer></v-spacer>
    <v-text-field
      v-model="search"
      append-icon="search"
      label="Search"
      single-line
      hide-details
    ></v-text-field>
  </v-card-title>
  <v-data-table
    :headers="headers"
    :items="desserts"
    :search="search"
  ></v-data-table>
</v-card>`,
        js: ` export default {
  data () {
    return {
      search: '',
      headers: [
        {
          text: 'Dessert (100g serving)',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        { text: 'Calories', value: 'calories' },
        { text: 'Fat (g)', value: 'fat' },
        { text: 'Carbs (g)', value: 'carbs' },
        { text: 'Protein (g)', value: 'protein' },
        { text: 'Iron (%)', value: 'iron' },
      ],
      desserts: [
        {
          name: 'Frozen Yogurt',
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: '1%',
        },
        {
          name: 'Ice cream sandwich',
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: '1%',
        },
        {
          name: 'Eclair',
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          iron: '7%',
        },
        {
          name: 'Cupcake',
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          iron: '8%',
        },
        {
          name: 'Gingerbread',
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          iron: '16%',
        },
        {
          name: 'Jelly bean',
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          iron: '0%',
        },
        {
          name: 'Lollipop',
          calories: 392,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          iron: '2%',
        },
        {
          name: 'Honeycomb',
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
          iron: '45%',
        },
        {
          name: 'Donut',
          calories: 452,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          iron: '22%',
        },
        {
          name: 'KitKat',
          calories: 518,
          fat: 26.0,
          carbs: 65,
          protein: 7,
          iron: '6%',
        },
      ],
    }
  },
}`,
        search: "",
        headers: [
          {
            text: "Dessert (100g serving)",
            align: "left",
            sortable: false,
            value: "name",
          },
          { text: "Calories", value: "calories" },
          { text: "Fat (g)", value: "fat" },
          { text: "Carbs (g)", value: "carbs" },
          { text: "Protein (g)", value: "protein" },
          { text: "Iron (%)", value: "iron" },
        ],
        desserts: [
          {
            name: "Frozen Yogurt",
            calories: 159,
            fat: 6.0,
            carbs: 24,
            protein: 4.0,
            iron: "1%",
          },
          {
            name: "Ice cream sandwich",
            calories: 237,
            fat: 9.0,
            carbs: 37,
            protein: 4.3,
            iron: "1%",
          },
          {
            name: "Eclair",
            calories: 262,
            fat: 16.0,
            carbs: 23,
            protein: 6.0,
            iron: "7%",
          },
          {
            name: "Cupcake",
            calories: 305,
            fat: 3.7,
            carbs: 67,
            protein: 4.3,
            iron: "8%",
          },
          {
            name: "Gingerbread",
            calories: 356,
            fat: 16.0,
            carbs: 49,
            protein: 3.9,
            iron: "16%",
          },
          {
            name: "Jelly bean",
            calories: 375,
            fat: 0.0,
            carbs: 94,
            protein: 0.0,
            iron: "0%",
          },
          {
            name: "Lollipop",
            calories: 392,
            fat: 0.2,
            carbs: 98,
            protein: 0,
            iron: "2%",
          },
          {
            name: "Honeycomb",
            calories: 408,
            fat: 3.2,
            carbs: 87,
            protein: 6.5,
            iron: "45%",
          },
          {
            name: "Donut",
            calories: 452,
            fat: 25.0,
            carbs: 51,
            protein: 4.9,
            iron: "22%",
          },
          {
            name: "KitKat",
            calories: 518,
            fat: 26.0,
            carbs: 65,
            protein: 7,
            iron: "6%",
          },
        ],
      },
      code8: {
        dialog: false,
        dialog2: false,
        dialog3: false,
        notifications: false,
        sound: true,
        widgets: false,
        items: [
          {
            title: "Click Me",
          },
          {
            title: "Click Me",
          },
          {
            title: "Click Me",
          },
          {
            title: "Click Me 2",
          },
        ],
        select: [
          { text: "State 1" },
          { text: "State 2" },
          { text: "State 3" },
          { text: "State 4" },
          { text: "State 5" },
          { text: "State 6" },
          { text: "State 7" },
        ],
      },
      code7: {
        dialog: false,
        dialog2: false,
        dialog3: false,
        notifications: false,
        sound: true,
        widgets: false,
        items: [
          {
            title: "Click Me",
          },
          {
            title: "Click Me",
          },
          {
            title: "Click Me",
          },
          {
            title: "Click Me 2",
          },
        ],
        select: [
          { text: "State 1" },
          { text: "State 2" },
          { text: "State 3" },
          { text: "State 4" },
          { text: "State 5" },
          { text: "State 6" },
          { text: "State 7" },
        ],
      },
      activePicker: null,
      date: null,
      menu: false,
    };
  },
  components: {
    KTCard,
  },
  methods: {
    newStudent: function () {
      alert("me");
    },
    save(date) {
      this.$refs.menu.save(date);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "dashboard" },
      { title: "Faculty", route: "Faculty" },
    ]);
  },
  watch: {
    dialog(val) {
      if (!val) return;

      setTimeout(() => (this.dialog = false), 4000);
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
};
</script>
