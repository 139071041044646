<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Student Information
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Update your student information</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="form-group row">
          <div class="col-lg-9 col-xl-6">
            <div class="image-input image-input-outline" id="kt_profile_avatar">
              <div
                class="image-input-wrapper"
                :style="{ backgroundImage: `url(${photo})` }"
              ></div>
              <label
                class="
                  btn
                  btn-xs
                  btn-icon
                  btn-circle
                  btn-white
                  btn-hover-text-primary
                  btn-shadow
                "
                data-action="change"
                data-toggle="tooltip"
                title=""
                data-original-title="Change avatar"
              >
                <i class="fa fa-pen icon-sm text-muted"></i>
                <input
                  type="file"
                  name="profile_avatar"
                  accept=".png, .jpg, .jpeg"
                  @change="onFileChange($event)"
                />
                <input type="hidden" name="profile_avatar_remove" />
              </label>
              <span
                class="
                  btn
                  btn-xs
                  btn-icon
                  btn-circle
                  btn-white
                  btn-hover-text-primary
                  btn-shadow
                "
                data-action="cancel"
                data-toggle="tooltip"
                title="Cancel avatar"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
              <span
                class="
                  btn
                  btn-xs
                  btn-icon
                  btn-circle
                  btn-white
                  btn-hover-text-primary
                  btn-shadow
                "
                data-action="remove"
                data-toggle="tooltip"
                title="Remove avatar"
                @click="current_photo = null"
              >
                <i class="ki ki-bold-close icon-xs text-muted"></i>
              </span>
            </div>
            <span class="form-text text-muted"
              >Allowed file types: png, jpg, jpeg.</span
            >
          </div>
        </div>
        <div class="form-group row">
          <div class="col-lg-6 col-xl-4">
            <v-text-field
              :rules="rules"
              counter="25"
              label="Last Name"
              v-bind:value="currentUserPersonalInfo.surname"
              outlined
              dense
            ></v-text-field>
          </div>

          <div class="col-lg-6 col-xl-4">
            <v-text-field
              :rules="rules"
              counter="25"
              label="First Name"
              outlined
              v-bind:value="currentUserPersonalInfo.name"
              dense
            ></v-text-field>
          </div>
          <div class="col-lg-6 col-xl-4">
            <v-text-field
              :rules="rules"
              counter="25"
              label="Middle Name"
              outlined
              v-bind:value="currentUserPersonalInfo.name"
              dense
            ></v-text-field>
          </div>
          <div class="col-lg-6 col-xl-4">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Birthday date"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                :active-picker.sync="activePicker"
                :max="new Date().toISOString().substr(0, 10)"
                min="1950-01-01"
                @change="update"
                label="Birth Date"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="col-lg-6 col-xl-4">
            <v-text-field
              :rules="rules"
              counter="25"
              label="Birth Place"
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-lg-6 col-xl-4">
            <v-select
              :items="code7.select"
              label="Gender"
              item-value="text"
              outlined
              dense
            ></v-select>
          </div>
          <div class="col-lg-6 col-xl-4">
            <v-text-field
              :rules="rules"
              counter="25"
              label="Religion"
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-lg-6 col-xl-4">
            <v-text-field
              :rules="rules"
              counter="25"
              label="Siblings"
              outlined
              dense
            ></v-text-field>
          </div>

          <div class="col-lg-6 col-xl-4">
            <v-textarea
              :rules="rules"
              label="Address"
              rows="1"
              outlined
              dense
            ></v-textarea>
          </div>
          <div class="col-lg-6 col-xl-4">
            <v-select
              :items="code7.select"
              label="Municipality/State"
              item-value="text"
              outlined
              dense
            ></v-select>
          </div>
          <div class="col-lg-6 col-xl-4">
            <v-select
              :items="code7.select"
              label="City"
              item-value="text"
              outlined
              dense
            ></v-select>
          </div>
          <div class="col-lg-6 col-xl-4">
            <v-select
              :items="code7.select"
              label="Baranggay"
              item-value="text"
              outlined
              dense
            ></v-select>
          </div>
          <div class="col-lg-6 col-xl-4">
            <v-text-field
              :rules="rules"
              counter="25"
              label="Father's Name"
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-lg-6 col-xl-4">
            <v-text-field
              :rules="rules"
              counter="25"
              label="Father's Occupation"
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-lg-6 col-xl-4">
            <v-text-field
              :rules="rules"
              counter="25"
              label="Mother's Name"
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-lg-6 col-xl-4">
            <v-text-field
              :rules="rules"
              counter="25"
              label="Mother's Occupation"
              outlined
              dense
            ></v-text-field>
          </div>
        </div><br />
        <v-divider></v-divider>
        <div class="row">          
          <div class="col-lg-12 col-xl-6">
            <h5 class="font-weight-bold mt-10 mb-6">
              Contact Info<br />
              <span class="text-muted font-weight-bold font-size-sm mt-1"
                >Update your contact information</span
              >
            </h5>
          </div>

          <div class="form-group row">
            <div class="col-lg-6 col-xl-4">
              <v-text-field
                :rules="rules"
                label="Phone"
                outlined
                append-icon="phone"
                v-bind:value="currentUserPersonalInfo.phone"
                dense
              ></v-text-field>
            </div>
            <div class="col-lg-6 col-xl-4">
              <v-text-field
                :rules="rules"
                label="Mobile"
                outlined
                append-icon="fa-mobile"
                v-bind:value="currentUserPersonalInfo.phone"
                dense
              ></v-text-field>
            </div>
            <div class="col-lg-6 col-xl-4">
              <v-text-field
                :rules="rules"
                label="Email"
                outlined
                append-icon="mdi-email"
                v-bind:value="currentUserPersonalInfo.email"
                dense
              ></v-text-field>
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";

export default {
  name: "PersonalInformation",
  data() {
    return {
      default_photo: "media/users/blank.png",
      current_photo: null,
      title: "Preliminary report",
      description: "California is a state in the western United States",
      rules: [(v) => v.length <= 25 || "Max 25 characters"],
      code7: {
        dialog: false,
        dialog2: false,
        dialog3: false,
        notifications: false,
        sound: true,
        widgets: false,
        items: [
          {
            title: "Click Me",
          },
          {
            title: "Click Me",
          },
          {
            title: "Click Me",
          },
          {
            title: "Click Me 2",
          },
        ],
        select: [
          { text: "State 1" },
          { text: "State 2" },
          { text: "State 3" },
          { text: "State 4" },
          { text: "State 5" },
          { text: "State 6" },
          { text: "State 7" },
        ],
      },
      picker: new Date().toISOString().substr(0, 10),
      activePicker: null,
      date: null,
      menu: false,
    };
  },
  mounted() {
    this.current_photo = this.currentUserPersonalInfo.photo;
  },
  methods: {
    update(date) {
      this.$refs.menu.save(date);
    },
    save() {
      var name = this.$refs.name.value;
      var surname = this.$refs.surname.value;
      var company_name = this.$refs.company_name.value;
      var phone = this.$refs.phone.value;
      var email = this.$refs.email.value;
      var company_site = this.$refs.company_site.value;
      var photo = this.photo;

      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch(UPDATE_PERSONAL_INFO, {
          name,
          surname,
          company_name,
          phone,
          email,
          company_site,
          photo,
        });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    cancel() {
      this.$refs.name.value = this.currentUserPersonalInfo.name;
      this.$refs.surname.value = this.currentUserPersonalInfo.surname;
      this.$refs.company_name.value = this.currentUserPersonalInfo.company_name;
      this.$refs.phone.value = this.currentUserPersonalInfo.phone;
      this.$refs.email.value = this.currentUserPersonalInfo.email;
      this.$refs.company_site.value = this.currentUserPersonalInfo.company_site;
      this.current_photo = this.currentUserPersonalInfo.photo;
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
};
</script>
