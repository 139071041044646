<template>
  <div class="d-flex flex-row">
    <div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px">
      <div class="row">
        <div class="col-lg-12" >
          <v-card tile min-height="100%" min-width="100%">
            
              
              <v-toolbar color="primary" dark>
                <v-toolbar-title>
                  <h3>Registration</h3>
                </v-toolbar-title>
              </v-toolbar>
            
            <v-card-text>
              <v-timeline align-top  style="left:-35%">
                <v-timeline >
                  <v-timeline-item
                    color="purple lighten-2"
                    fill-dot
                    right
                    icon="mdi-account"
                  >
                    <v-card min-width="18em">
                      <v-card-title class="purple lighten-2 justify-center">
                        <a href="#"><h3 class="display white--text font-weight-light">
                          Registration
                        </h3></a>
                      </v-card-title>
                    </v-card>
                  </v-timeline-item>

                  <v-timeline-item color="amber lighten-1" fill-dot right icon="mdi-file-multiple">
                    <v-card min-width="18em">
                      <v-card-title class="amber lighten-1 justify-center" >
                       <a href="#"> <h3
                          class="display  white--text font-weight-light"
                        >
                          Requirements
                        </h3></a>
                      </v-card-title>
               
                    </v-card>
                  </v-timeline-item>

                  <v-timeline-item color="cyan lighten-1" fill-dot right icon="mdi-note-outline">
                    <v-card  min-width="18em">
                      <v-card-title class="cyan lighten-1 justify-center">                     
                       <a href="#"> <h3 class="display white--text font-weight-light">
                          Verification
                        </h3></a>
                      </v-card-title>                    
                    </v-card>
                  </v-timeline-item>
                  <v-timeline-item color="red lighten-1" fill-dot right icon="mdi-magnify" >
                    <v-card min-width="18em">
                      <v-card-title class="red lighten-1 justify-center">
                      <a href="#">  <h3
                          class="display white--text font-weight-light"
                        >
                          Assessment
                        </h3></a>
                      </v-card-title>
                      
                    </v-card>
                  </v-timeline-item>
                  <v-timeline-item color="green lighten-1" fill-dot right icon="mdi-currency-usd">
                    <v-card min-width="18em">
                      <v-card-title class="green lighten-1 justify-center">                       
                        <a href="#"><h3 class="display white--text font-weight-light">
                          Payment
                        </h3></a>
                      </v-card-title>          
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </v-timeline>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
    <div class="flex-row-fluid ml-lg-8">
      <div class="row">
        <div class="col-lg-12">
          <v-card tile min-height="100%">
            <v-card-text>
              <WizEnrollee></WizEnrollee>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.v-timeline-item__divider {
  min-width: 64px;
}
.v-timeline--dense .v-timeline-item__body {
  max-width: calc(100% - 10rem);
}
.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before {
    left: calc(32px - 1px)
}
</style>
<script>
import WizEnrollee from "@/module/components/enrollment/WizEnrollee.vue";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  components: {
    WizEnrollee,
  },
  data: () => ({}),
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Student Registration", route: "registration" },
    ]);
  },
};
</script>
